import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../core/auth/_services/auth.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardServicesService } from '../../../services/dashboard-services/dashboard-services.service';
import { runInThisContext } from 'vm';
import { Observable } from 'rxjs';
import { LoginService } from '../../../services/login/login.service'
import { PowerBILogService } from '../../../services/log/powerBILog.service';
import { Screen } from '../../../models/enums/screen.enum';

@Component({
  selector: 'kt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss',
    './css/bootstrap.min.css',
    './css/bootstrap.min.2.css',
    './css/bootstrap.min.3.css',
    './css/fonts.css',
    './css/icons.css',
    './css/media.css',
    './css/root.css']
})
export class LoginComponent implements OnInit {
  userFormGroup: FormGroup
  storageFormGroup: FormGroup
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'
  email: string = '';
  password: string = '';
  submitted = false
  hasError: boolean
  errorMessage: string
  isPasswordVisible: boolean
  public saveCredential: boolean = false
  blnumberdata: any
  blNumber: any
  ReferenceNumber: any
  public checkValidation: boolean = false;
  public page: number = 1;
  public size: number = 10;
  public payload$: Observable<any>;
  public orderInProgressList: any;
  checkNull = false;
  public module: any;
  public objectType: any;
  public objectName: any;
  public clickEvent: any;
  public action: any;
  public screenId: any;
  public refNumber: any;
  public refType: any;
  public subModule: any;



  constructor(private authService: AuthService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private router: Router,
    private dashboardServices: DashboardServicesService,
    private loginService: LoginService,
    private powerBIService: PowerBILogService
  ) {
    this.isPasswordVisible = false
  }

  ngOnInit() {
    this.getform()
    this.getCookie()
    this.generateForm()

    this.screenId = Screen.Recuperarcontrasena;
    this.module = 'Recuperar Contrasena';
    this.clickEvent = "Click";
    this.action = "Redirect";
    this.objectType = "Button";
    this.objectName = "IniciarSesion";
    this.subModule = null;
    this.integrateAuditApi();
  }

  integrateAuditApi() {
    //debugger
    this.powerBIService.createPayload(this.screenId, this.module, this.clickEvent, this.action, this.objectType, this.objectName, this.refNumber, this.refType, this.subModule);
  }

  getform() {
    this.userFormGroup = this.formBuilder.group({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(this.emailPattern),
      ]),
      password: new FormControl('', [
        Validators.required
      ]),
    })
  }

  getCookie() {
    const username = ('; ' + document.cookie).split(`; username=`).pop().split(';')[0];
    const password = ('; ' + document.cookie).split(`; password=`).pop().split(';')[0];
    const checked = ('; ' + document.cookie).split(`; checked=`).pop().split(';')[0];
    this.saveCredential = checked == 'true' ? true : false;
    if (username && password) {
      this.email = username
      this.password = password
      // this.logIn(this.email,  this.password)
    }
  }

  login() {
    localStorage.removeItem('Selectedcompany');
    this.submitted = true
    if (this.userFormGroup.invalid) {
      return
    }
    const userCredentials = this.userFormGroup.value as { email; password }
    localStorage.setItem('emial', userCredentials.email)
    this.logIn(userCredentials.email, userCredentials.password)


    this.action = "Redirect";
    this.objectType = "Button";
    this.objectName = "LoginMardomGo";
    this.subModule = "Entrar al Portal"
    this.integrateAuditApi();
  }


  private showErrorMessage(message: string) {
    this.errorMessage = message
    this.hasError = true
    this.cd.detectChanges();

  }

  private async logIn(email: string, password: string) {
    try {
      await this.loginService.loginApi(this.email, this.password).subscribe((response: any) => {
        this.authService.setloginDetails(response);
      })
    } catch (error) {
      this.showErrorMessage(error)
    }
  }

  setCookie(event) {
    if (event.target.checked) {
      document.cookie = "username=" + this.userFormGroup.value.email;
      document.cookie = "password=" + this.userFormGroup.value.password;
      document.cookie = "checked=" + true;
    } else {
      document.cookie = "username=" + "";
      document.cookie = "password=" + "";
      document.cookie = "checked=" + false;
    }
    this.action = "Redirect";
    this.objectType = "Button";
    this.objectName = "RecoverMyPassword";
    this.subModule = 'Recordar Mi Contrasena';
    this.integrateAuditApi();

  }

  onChangeEvent(event) {
    const username = ('; ' + document.cookie).split(`; username=`).pop().split(';')[0];
    if (username != event.target.value) {
      this.saveCredential = false;
      let check = document.getElementById("checkbox") as HTMLInputElement;
      check.checked = false
      document.cookie = "username=" + "";
      document.cookie = "password=" + "";
      document.cookie = "checked=" + false;
      this.cd.detectChanges();
    }
  }

  hideErrorMessage() {
    this.hasError = false
  }
  showPassword() {
    this.isPasswordVisible = !this.isPasswordVisible
  }

  generateForm() {
    this.storageFormGroup = this.formBuilder.group({
      searchRecord: new FormControl('', [
        Validators.required,
      ])
    })
  }

  search() {
    this.orderInProgressList = '';
    if (this.storageFormGroup.invalid) {
      return
    } else {
      let payload$ = {
        FromDate: '',
        ToDate: '',
        Refrenceno: this.storageFormGroup.controls.searchRecord.value,
        Status: '',
        refrencetype: 'bl',
        orderon: 'dt',
        orderby: 'desc',
        startswith: this.search
      }
      this.dashboardServices.getOrderInProgressList(this.page, this.size, payload$).subscribe(response => {
        this.orderInProgressList = response.response
        if (this.orderInProgressList.length > 0) {
          this.router.navigate(['order/' + this.storageFormGroup.controls.searchRecord.value]);
        }
        this.checkValidation = true;
        this.checkNull = true;
        this.cd.detectChanges();
      })
    }

    this.action = "Search";
    this.objectType = "Button";
    this.objectName = "SearchAsGuest";
    this.refNumber = this.storageFormGroup.controls.searchRecord.value;
    this.refType = 'bl';
    this.subModule = 'Buscar "as a guest"';
    this.integrateAuditApi();

  }


  reset() {
    this.checkNull = false;
  }

  gotokiosko() {
    this.action = "Redirect";
    this.objectType = "Button";
    this.objectName = "OpenKiosko";
    this.subModule = 'Ir Ahora "to Kiosko"';
    this.integrateAuditApi();
    window.open("https://kiosko.uat.mardom.com/", '_blank');

  }


}
