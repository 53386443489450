import { Inject, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { LogService } from '../../../app/services/log/log.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../core/reducers';
import * as jwt_decode from "jwt-decode";
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { TransferrequestService } from '../transferrequest/transferrequest.service';


@Injectable({
    providedIn: 'root'
})
export class PowerBILogService {
    public payload$: Observable<any>;
    public username;
    public roleName;
    public payload: any;
    public authToken;

    constructor(
        private datepipe: DatePipe,
        @Inject(DOCUMENT) private document: Document,
        private store: Store<AppState>,
        private logService: LogService,
        private transferRequestService: TransferrequestService,
    ) { }



    createPayload(moduleId, module, clickEvent, action, objectType, objectName, refNo, refType, subModule): any {
        this.payload$ = this.store.pipe(select(selectAuthState));
        this.payload$.subscribe(res => {
            try {
                if (!res.authToken) {
                    const payload = {
                        Sitio: window.location.href,
                        Modulo: module,
                        SubModulo: subModule,
                        ModuloId: moduleId,
                        TipoDeObjeto: objectType,
                        NombreDelObjeto: objectName,
                        Evento: clickEvent,
                        Accion: action,
                        IdentificacionDeReferencia: refNo,
                        TipoDeReferencia: refType,
                        BuscarPorFecha: null,
                        BuscarPorEstado: null,
                        ListaOrdenarPor: null,
                        BLIDKiosko: null,
                        TaraIDKiosko: null,
                        CedulaIdKiosko: null,
                        TipoDePago: null,
                        AceptadosTerminosCondiciones: null,
                        RNC: null,
                        NombreComercial: null,
                        IdDeTransaccion: null,
                        CantidadDeVeces: null,
                        RolDelUsuario: this.roleName,
                        Usuario: this.username,
                        FechaDeRegistro: this.datepipe.transform((new Date), 'dd-MM-yyyy'),
                        HoraDeRegistro: this.datepipe.transform(new Date(), 'hh:mm:ss a', 'GMT-4').toUpperCase().replace(/(\d{2}:\d{2}:\d{2})\s*(A|M)\.\s*(A|M)\./g, '$1 $2$3'),
                        NumeroDeCuentaDeCliente: null,
                        Version: this.checkEnvironment(),
                        TipoDeNavegador: this.getBrowserType(),
                        TipoDeDipositivo: this.getDeviceType(),
                        SistemaOperativo: this.getOperatingSystem(),
                        VersionMardomGO: "1.0.0",
                        ErrorTrackingSistemaOperativoOg: null,
                        CapacidadDeRespuestaLoadTime: null,
                        UbicacionPais: null,
                    };
                    console.log('Payload Generado:', payload)
                    return this.logService.postWithPayload(payload, this.authToken);
                }
                const tokenData = jwt_decode(res.authToken);
                this.authToken = res.authToken;
                this.username = res.user.email; // Valor predeterminado
                this.roleName = tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0];

                this.transferRequestService.getClientCode().subscribe({
                    next: (data) => {
                        console.log('API response:', data);
                        const payload = {
                            Sitio: window.location.href,
                            Modulo: module,
                            SubModulo: subModule,
                            ModuloId: moduleId,
                            TipoDeObjeto: objectType,
                            NombreDelObjeto: objectName,
                            Evento: clickEvent,
                            Accion: action,
                            IdentificacionDeReferencia: refNo,
                            TipoDeReferencia: refType,
                            BuscarPorFecha: null,
                            BuscarPorEstado: null,
                            ListaOrdenarPor: null,
                            BLIDKiosko: null,
                            TaraIDKiosko: null,
                            CedulaIdKiosko: null,
                            TipoDePago: null,
                            AceptadosTerminosCondiciones: null,
                            RNC: null,
                            NombreComercial: null,
                            IdDeTransaccion: null,
                            CantidadDeVeces: null,
                            RolDelUsuario: this.roleName,
                            Usuario: this.username,
                            FechaDeRegistro: this.datepipe.transform((new Date), 'dd-MM-yyyy'),
                            HoraDeRegistro: this.datepipe.transform(new Date(), 'hh:mm:ss a', 'GMT-4')
                                .toUpperCase()
                                .replace(/(\d{2}:\d{2}:\d{2})\s*(A|M)\.\s*(A|M)\./g, '$1 $2$3'),
                            NumeroDeCuentaDeCliente: null,
                            Version: this.checkEnvironment(),
                            TipoDeNavegador: this.getBrowserType(),
                            TipoDeDipositivo: this.getDeviceType(),
                            SistemaOperativo: this.getOperatingSystem(),
                            VersionMardomGO: '1.0.0',
                            ErrorTrackingSistemaOperativoOg: null,
                            CapacidadDeRespuestaLoadTime: null,
                            UbicacionPais: null,
                            Nombre_Empresa: data.Company,
                            Codigo_Empresa: data.ClientCode.toString(),
                            Codigo_Usuario: data.IdAuth0,
                            Empresa_RNC: data.RNC,
                        };
                        console.log('Payload Generado: ', payload);
                        this.logService.postWithPayload(payload, this.authToken);
                    },
                    error: (err) => {
                        if (err.status === 401) {
                            location.reload();
                        }
                    }
                })
            }
            catch(error) {
                console.log("Manejo del payload")
            }
        })
    }

    // auditData(moduleId, module, clickEvent, action) {
    //     const payload = this.createPayload(moduleId, module, clickEvent, action);
    //     this.logService.postWithPayload(payload,
    //         this.authToken);
    // }

    getBrowserType(): string {
        const userAgent = navigator.userAgent.toLowerCase();
        console.log("Check Browesers:: ", userAgent);

        if (/edg/i.test(userAgent)) {
            return "Edge";
        } else if (/chrome/i.test(userAgent)) {
            return "Chrome";
        } else if (/safari/i.test(userAgent) && !/chrome/i.test(userAgent)) {
            return "Safari";
        } else if (/firefox/i.test(userAgent)) {
            return "Firefox";
        } else if (/msie|trident/i.test(userAgent)) {
            return "Internet Explorer";
        } else {
            return "Unknown";
        }
    }

    getDeviceType(): string {
        const userAgent = navigator.userAgent;

        if (userAgent.match(/Android/i) || userAgent.match(/webOS/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPad/i) || userAgent.match(/iPod/i)) {
            return 'Mobile';
        } else if (userAgent.match(/iPad/i) || userAgent.match(/Tablet/i)) {
            return 'Tablet';
        } else {
            return 'Desktop';
        }
    }

    getOperatingSystem() {
        const userAgent = this.document.defaultView.navigator.userAgent || 'Unknown';

        if (userAgent.match(/Android/i)) {
            return 'Android';
        } else if (userAgent.match(/webOS/i)) {
            return 'webOS';
        } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
            return 'iOS';
        } else if (userAgent.match(/Windows Phone/i)) {
            return 'Windows Phone';
        } else if (userAgent.match(/Windows/i)) {
            return 'Windows';
        } else if (userAgent.match(/Macintosh/i)) {
            return 'Macintosh';
        } else if (userAgent.match(/Linux/i)) {
            return 'Linux';
        } else {
            return 'Unknown';
        }
    }

    checkEnvironment() {
        const userAgent = this.document.defaultView.navigator.userAgent.toLowerCase();
        const mobileKeywords = ['iphone', 'ipad', 'android', 'windows phone', 'mobile'];
        const isMobile = mobileKeywords.some(keyword => userAgent.includes(keyword));

        if (isMobile) {
            return 'Mobile';
        } else {
            return 'Web';
        }
    }


}
